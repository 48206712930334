import request from '@/utils/request'

// 用户注册
export function userRegister(data) {
  return request('post', '/api/user/register', data)
}

// 用户登录(获取Token)
export function userLogin(data) {
  return request('post', '/api/user/login', data)
}

// 用户信息
export function myUserInfo() {
  return request('post', '/api/user/info')
}

// 用户修改
export function updateUserInfo(data) {
  return request('post', '/api/user/update', data)
}

// 用户删除
export function deleteUserList(data) {
  return request('post', '/api/user/delete', data)
}

// 用户列表
export function pageUserList(data) {
  return request('post', '/api/user/userList', data)
}

// 用户下拉列表
export function userNameList() {
  return request('get', '/api/user/selectList')
}

// 用户信息修改
export function updateUserAvatar(data) {
  return request('post', '/api/user/updateInfo', data)
}




