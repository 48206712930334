<template>
  <div id="upload">
    <el-button type="text" @click="onOpen">点击上传</el-button>
    <el-dialog :title="upload.title" :visible.sync="uploadShow" width="30%">
      <el-upload
        class="upload-demo"
        action="/api/upload"
        name="files"
        ref="upload"
        :headers="Token()"
        :limit="upload.limit"
        :multiple="upload.multiple"
        :accept="upload.accept"
        :on-success="onSuccess"
        :on-remove="onRemove"
        :file-list="fileList"
        :auto-upload="false"
        list-type="picture"
        auto-upload
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <div slot="tip" class="el-upload__tip">
          {{ upload.tip }}
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    fileType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      fileTypeOpt: {
        1: {
          title: "多图上传",
          multiple: true,
          limit: 10,
          tip: "只能上传最多十张jpg/png文件，文件大小不超过500kb",
          accept: ".png,.jpg,.gif,.jpeg",
        },
        2: {
          title: "单图上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单张jpg/png文件，文件大小不超过500kb",
          accept: ".png,.jpg,.gif,.jpeg",
        },
        3: {
          title: "多文件上传",
          multiple: true,
          limit: 10,
          tip: "只能上传最多十个文件，文件大小不超过500kb",
          accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
        },
        4: {
          title: "单文件上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单个文件，文件大小不超过500kb",
          accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
        },
        5: {
          title: "图文上传",
          multiple: true,
          limit: 10,
          tip: "能上传最多十个图片和文件，文件大小不超过500kb",
          accept: ".png,.jpg,.gif,.jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
        },
      },
      uploadShow: false, //弹框开关
      fileList: [], //文件列表
      fileIds: "", //文件ID集合
    };
  },
  computed: {
    upload() {
      return this.fileTypeOpt[this.fileType];
    },
  },
  methods: {
    // 【转换】获取Token
    Token() {
      let token = localStorage.getItem("yu_token");
      return { Authorization: token };
    },
    getFileIds(fileList) {
      let fileIds = [];
      for (let i of fileList) {
        fileIds = [...fileIds, i.response.data.fileId];
      }
      return (this.fileIds = fileIds.toString());
    },
    // 【监听】弹框开启
    onOpen() {
      this.uploadShow = true;
    },
    // 【监听】上传成功
    onSuccess(res, file, fileList) {
      this.getFileIds(fileList);
    },
    // 【监听】删除预览
    onRemove(file, fileList) {
      this.getFileIds(fileList);
    },
    // 【监听】保存按钮
    onConfirm() {
      if (this.fileIds == "") return this.$message.error("请选择文件");
      this.uploadShow = false;
      this.$emit("fileIds", this.fileIds);
      this.fileList = [];
      this.fileIds = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.el-upload-list {
  overflow: auto;
}
</style>