<template>
  <div id="userinfo">
    <el-descriptions class="margin-top" title="个人资料" :column="3" border>
      <template slot="extra">
        <el-button type="primary" size="small" @click="onEdit">修改资料</el-button>
      </template>
      <!-- 用户名 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user-solid"></i>
          用户名
        </template>
        {{ userInfo.userName || "--" }}
      </el-descriptions-item>
      <!-- 手机号 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{ userInfo.userPhone || "--" }}
      </el-descriptions-item>
      <!-- 用户权限 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-key"></i>
          用户权限
        </template>
        {{ userAuthObj[userInfo.userAuth] }}
      </el-descriptions-item>
      <!-- 昵称 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          昵称
        </template>
        {{ userInfo.userNickName || "--" }}
      </el-descriptions-item>
      <!-- 注册日期 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          注册日期
        </template>
        {{ formatterDate(userInfo.createdAt, "yyyy-MM-dd") || "--" }}
      </el-descriptions-item>
      <!-- 最近登录 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          最近登录
        </template>
        {{ formatterDate(userInfo.loginAt) || "--" }}
      </el-descriptions-item>
      <!-- 头像 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-picture-outline"></i>
          头像
        </template>
        <el-image
          class="user-avatar"
          :src="
            (userInfo.userAvatarUrl && userInfo.userAvatarUrl.filePath) ||
            circleUrl
          "
          :preview-src-list="
            userInfo.userAvatarUrl && [userInfo.userAvatarUrl.filePath]
          "
        >
        </el-image>
        <UpLoad @fileIds="getFileIds" :fileType="2"></UpLoad>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { myUserInfo, updateUserAvatar } from "@/api/setting/userlist";
import { userAuthObj } from "@/db/setting/userlist";
import { formatterDate } from "@/utils/utils";
import UpLoad from "@/components/upload";
export default {
  components: { UpLoad },
  data() {
    return {
      userAuthObj,
      formatterDate,
      userInfo: {},
      token: { Authorization: localStorage.getItem("yu_token") },
      circleUrl: require("../../assets/images/layout/head.png"), //默认头像
    };
  },
  mounted() {
    this.myUserInfo();
  },
  methods: {
    // 【请求】我的资料详情
    myUserInfo() {
      myUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },

    // 【请求】修改用户信息
    updateUserAvatar() {
      let data = this.userInfo;
      updateUserAvatar(data).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.myUserInfo();
        }
      });
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.userInfo.userAvatar = fileIds;
      this.updateUserAvatar();
    },

    // 【监听】修改个人资料
    onEdit(){
      this.$message('暂无修改权限')
    },
  },
};
</script>


<style lang="scss" scoped>
#userinfo {
  padding: 24px;
  box-sizing: border-box;
}

.user-avatar {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  border-radius: 50%;
}
</style>
